import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState, Fragment } from "react";
import { useFormik } from "formik";
import { couponSchema } from "../../../schemas";
import Table from "../../../components/tables/table";
import { Dialog, Switch, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import ReservationServices from "../../../ApiServices/ReservationServices";
import moment from "moment";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getAllTests } from "../../../redux/test/actions";
import { getAllPackages } from "../../../redux/package/actions";
import { getAllLocations } from "../../../redux/location/actions.js";
import { toast } from "react-toast";

function categoryStatusToggle(...categoryStatus) {
  return categoryStatus.filter(Boolean).join(" ");
}
function randomiseStatusToggle(...randomiseStatus) {
  return randomiseStatus.filter(Boolean).join(" ");
}

const AddCoupon = () => {
  const pages = [{ title: "Add Coupon", href: "/add-coupon", module_id: 28 }];
  const [couponList, setCouponList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [couponId, setCouponId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    couponId: null,
  });
  const [isPercentage, setIsPercentage] = useState(false);
  const [randomise, setRandomise] = useState(false);

  const [userList, setUserList] = useState(false);
  const [locationList, setLocationList] = useState(false);
  const [testList, setTestList] = useState(false);
  const [packList, setPackList] = useState(false);

  const [multiTestId, setMultiTestId] = useState();
  const [multiUserId, setMultiUserId] = useState();
  const [multiPackageId, setMultiPackageId] = useState();
  const [multiLocationId, setMultiLocationId] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    test: { loading: tLoading, list: tList },
    package: { loading: pLoading, list: pList },
    location: { loading: lLoading, list: l_List },
  } = useSelector((state) => state);

  useEffect(() => {
    document.title = "Labib | Coupons";
    getAllUsers();
    getAllCoupons();
    dispatch(getAllTests());
    dispatch(getAllLocations());
    dispatch(getAllPackages("active"));
  }, []);

  useEffect(() => {
    let locations = [{ label: "ALL", value: "all" }];
    if (l_List?.length > 0) {
      l_List.forEach((element) => {
        let obj = {
          label: element.label,
          value: element.value,
          m_user_loc_id: 0,
        };

        locations.push(obj);
      });
      setLocationList(locations);
    }
  }, [l_List]);

  useEffect(() => {
    let tests = [{ label: "ALL", value: "all" }];
    if (tList?.length > 0) {
      tList.forEach((element) => {
        let obj = {
          ...element,
        };

        tests.push(obj);
      });
      setTestList(tests);
    }
  }, [tList]);

  useEffect(() => {
    let packages = [{ label: "ALL", value: "all" }];
    if (pList?.length > 0) {
      pList.forEach((element) => {
        let obj = {
          ...element,
        };

        packages.push(obj);
      });
      setPackList(packages);
    }
  }, [pList]);
  console.log("fk_location_ids=>", locationList);
  console.log("tList=>", tList);
  console.log("pList=>", pList);

  const getAllUsers = async () => {
    try {
      setIsLoading(true);
      const { data } = await ReservationServices.getAllUserList();
      // console.log("All Users Data", data)
      if (data.status) {
        let newUsers = data.data;
        let users = [{ label: "ALL", value: "all" }];
        if (newUsers?.length > 0) {
          newUsers.forEach((el) => {
            // let obj = {
            //     ...element
            // }
            let obj = { ...el, label: el.user_first_name, value: el.user_id };

            users.push(obj);
          });
          setUserList(users);
        }
        // let users = newUsers.map((el) => {
        //     let obj = { ...el, label: el.user_first_name, value: el.user_id }
        //     // console.log("New User Obj", obj)
        //     return obj
        // })
        // setUserList(users);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllCoupons = async () => {
    try {
      setIsLoading(true);
      const { data } = await MasterServices.getAllCoupons();
      if (data?.status) {
        setCouponList(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const addCoupon = async (payload) => {
    try {
      setIsLoading(true);

      let body = payload;
      console.log("couponId", couponId);
      const res = await MasterServices.saveCoupon(
        body,
        couponId == undefined || couponId == null || couponId == ""
          ? ""
          : couponId
      );
      if (res.status) {
        toast.success("Coupon Saved Successfully");
        setIsPercentage(false);
      }
      // console.log("Add State Response", res)
      setIsLoading(false);
    } catch (error) {
      console.log("addCoupon", error);
      setIsLoading(false);
    }
  };

  const initialValues = {
    cp_name: "",
    cp_discountValue: "",
    cp_toDate: "",
    cp_fromDate: "",
    fk_user_ids: "",
    fk_test_ids: "",
    fk_package_ids: "",
    fk_location_ids: "",
    cp_max_discount: "",
    cp_limit_per_user: "",
    cp_numUses: "",
    cp_code_prefix: "",
    cp_is_random: "",
    cp_code_count: "",
  };

  const [formCoupon, setFormCoupon] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      setCouponId(id);
      const editValues = {
        cp_name: obj.cp_name,
        cp_description: obj.cp_description,
        cp_discountValue: obj.cp_discountValue,
        cp_toDate: moment(obj.cp_toDate).format("YYYY-MM-DD"),
        fk_user_id: obj.fk_user_id,
      };
      setIsPercentage(obj.cp_value_is_percentage);
      setFormCoupon(editValues);
    } else {
      setCouponId("");
      setFormCoupon(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };
  const onDeleteOpen = (couponId) => {
    setConfirmationModal({ couponId, status: true });
  };

  // TODO:
  const onDeleteCoupon = (couponId) => {
    setConfirmationModal({ couponId: null, status: false });
    //    let payload = {
    //     cp_is_active: true
    //    }
    //     MasterServices.deactivateCoupon(couponId);
  };

  const handleMultiTest = (event) => {
    // initialValues.fk_test_ids = JSON.stringify(event);

    // setMultiTestId(event)
    // console.log(initialValues.fk_test_ids);

    let allTest = [];
    if (event.some((item) => item.label === "ALL")) {
      // Filter out items with label 'ALL' from locationList
      allTest = testList.filter((item) => item.label !== "ALL");
      setMultiTestId(allTest);
      initialValues.fk_test_ids = JSON.stringify(allTest);
    } else {
      // If no 'ALL' label, use the event array as is
      setMultiTestId(event);
      initialValues.fk_test_ids = JSON.stringify(event);
    }
    console.log(initialValues.fk_test_ids);
  };

  const handleMultiLocation = (event) => {
    // initialValues.fk_location_ids = JSON.stringify(event);

    // setMultiLocationId(event)
    let allLoc = [];
    if (event.some((item) => item.label === "ALL")) {
      // Filter out items with label 'ALL' from locationList
      allLoc = locationList.filter((item) => item.label !== "ALL");
      setMultiLocationId(allLoc);
      initialValues.fk_location_ids = JSON.stringify(allLoc);
    } else {
      // If no 'ALL' label, use the event array as is
      setMultiLocationId(event);
      initialValues.fk_location_ids = JSON.stringify(event);
    }
    console.log(initialValues.fk_location_ids);
  };

  const handleMultiUser = (event) => {
    initialValues.fk_user_ids = JSON.stringify(event);

    setMultiUserId(event);
    console.log(initialValues.fk_user_ids);

    let allUser = [];
    if (event.some((item) => item.label === "ALL")) {
      // Filter out items with label 'ALL' from locationList
      allUser = userList.filter((item) => item.label !== "ALL");
      setMultiUserId(allUser);
      initialValues.fk_user_ids = JSON.stringify(allUser);
    } else {
      // If no 'ALL' label, use the event array as is
      setMultiUserId(event);
      initialValues.fk_user_ids = JSON.stringify(event);
    }
    console.log(initialValues.fk_user_ids);
  };

  const handleMultiPackage = (event) => {
    // initialValues.fk_package_ids = JSON.stringify(event);

    // setMultiPackageId(event)
    // console.log(initialValues.fk_package_ids);

    let allPack = [];
    if (event.some((item) => item.label === "ALL")) {
      // Filter out items with label 'ALL' from locationList
      allPack = packList.filter((item) => item.label !== "ALL");
      setMultiPackageId(allPack);
      initialValues.fk_package_ids = JSON.stringify(allPack);
    } else {
      // If no 'ALL' label, use the event array as is
      setMultiPackageId(event);
      initialValues.fk_package_ids = JSON.stringify(event);
    }
    console.log(initialValues.fk_package_ids);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formCoupon,
      validationSchema: couponSchema,
      onSubmit: (values, action) => {
        // console.log("Submit Clicked", { ...values, cp_value_is_percentage: isPercentage });
        let body = {
          ...values,
          cp_value_is_percentage: isPercentage,
          cp_is_random: randomise,
          fk_package_ids: multiPackageId,
          fk_user_ids: multiUserId,
          fk_location_ids: multiLocationId,
          fk_test_ids: multiTestId,
        };
        let couponAdd = addCoupon(body);
        action.resetForm();
        getAllCoupons();
        setTimeout(() => {
          navigate("/coupons");
        }, 2000);
      },
    });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteCoupon(confirmationModal.couponId)}
        confirmationButtonText="Deactivate Coupon"
      />
      <Breadcrumb pages={pages} />
      {/* Removed pLoading from below condition because loding packages is taking lots of time */}
      {isLoading || tLoading || lLoading ? (
        <FallingLinesLoader />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <h1 className="text-xl font-semibold text-gray-900">
                {couponId ? "Update" : "Add"} Coupon
              </h1>
              <p className="mt-1 text-sm text-gray-500">
                This information will be displayed publicly so be careful what
                you share.
              </p>
            </div>
            <div className="">
              <div className="flex gap-5 mt-3">
                {/* Coupon Code */}
                <div className="mt-1 basis-1/2">
                  <div>
                    <label
                      htmlFor="cp_name"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Coupon Name
                    </label>
                  </div>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      value={values.cp_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      placeholder="Enter Coupon Code"
                      name="cp_name"
                      autoComplete="off"
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
                    />
                    {errors.cp_name && touched.cp_name ? (
                      <p className="text-red-600 text-sm">{errors.cp_name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-1 basis-1/2">
                  <div>
                    <label
                      htmlFor="cp_max_discount"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Max Discount
                    </label>
                  </div>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      value={values.cp_max_discount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      placeholder="Enter Max Discount Value"
                      name="cp_max_discount"
                      autoComplete="off"
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
                    />
                    {errors.cp_max_discount && touched.cp_max_discount ? (
                      <p className="text-red-600 text-sm">
                        {errors.cp_max_discount}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="flex gap-5 mt-3">
                {/* Coupon Code */}
                <div className="mt-1 basis-1/2">
                  <div>
                    <label
                      htmlFor="cp_limit_per_user"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Limit Per User
                    </label>
                  </div>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      value={values.cp_limit_per_user}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      placeholder="Enter Limit Per User"
                      name="cp_limit_per_user"
                      autoComplete="off"
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
                    />
                    {errors.cp_limit_per_user && touched.cp_limit_per_user ? (
                      <p className="text-red-600 text-sm">
                        {errors.cp_limit_per_user}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-1 basis-1/2">
                  <div>
                    <label
                      htmlFor="cp_numUses"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Number Of Uses
                    </label>
                  </div>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      value={values.cp_numUses}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      placeholder="Enter Number Of Uses"
                      name="cp_numUses"
                      autoComplete="off"
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
                    />
                    {errors.cp_numUses && touched.cp_numUses ? (
                      <p className="text-red-600 text-sm">
                        {errors.cp_numUses}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="flex gap-5 mt-3">
                <div className="mt-1 basis-1/2">
                  <div>
                    <label
                      htmlFor="cp_fromDate"
                      className="block text-sm font-medium text-gray-900"
                    >
                      From Date
                    </label>
                  </div>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      value={values.cp_fromDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="date"
                      placeholder="Enter To Date"
                      name="cp_fromDate"
                      autoComplete="off"
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
                    />
                    {errors.cp_fromDate && touched.cp_fromDate ? (
                      <p className="text-red-600 text-sm">
                        {errors.cp_fromDate}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-1 basis-1/2">
                  <div>
                    <label
                      htmlFor="cp_toDate"
                      className="block text-sm font-medium text-gray-900"
                    >
                      To Date
                    </label>
                  </div>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      value={values.cp_toDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="date"
                      placeholder="Enter To Date"
                      name="cp_toDate"
                      autoComplete="off"
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
                    />
                    {errors.cp_toDate && touched.cp_toDate ? (
                      <p className="text-red-600 text-sm">{errors.cp_toDate}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="flex gap-5 mt-3">
                <div className="basis-1/2">
                  <label
                    htmlFor="fk_package_ids"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Packages
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 cursor-pointer">
                    <Select
                      isMulti
                      name="fk_package_ids"
                      id="fk_package_ids"
                      value={multiPackageId}
                      onChange={(e) => handleMultiPackage(e)}
                      onBlur={handleBlur}
                      options={packList}
                      className="basic-multi-select"
                      classNamePrefix="Select Package"
                    />
                    {errors.fk_package_ids && touched.fk_package_ids ? (
                      <p className="text-red-600 text-sm">
                        {errors.fk_package_ids}
                      </p>
                    ) : null}
                  </div>
                </div>
                {/* User Multiselect */}
                <div className="basis-1/2">
                  <label
                    htmlFor="fk_user_ids"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Users
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      // defaultValue={testList[0]}
                      isMulti
                      name="fk_user_ids"
                      id="fk_user_ids"
                      value={multiUserId}
                      onChange={(e) => handleMultiUser(e)}
                      onBlur={handleBlur}
                      options={userList}
                      className="basic-multi-select"
                      classNamePrefix="Select User"
                    />
                    {errors.fk_user_ids && touched.fk_user_ids ? (
                      <p className="text-red-600 text-sm">
                        {errors.fk_user_ids}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="flex gap-5 mt-3">
                {/* Test Multiselect  */}
                <div className="basis-1/2">
                  <label
                    htmlFor="fk_test_ids"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Tests
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      // defaultValue={testList[0]}
                      isMulti
                      name="fk_test_ids"
                      id="fk_test_ids"
                      value={multiTestId}
                      onChange={(e) => handleMultiTest(e)}
                      onBlur={handleBlur}
                      options={testList}
                      className="basic-multi-select"
                      classNamePrefix="Select Test"
                    />
                    {errors.fk_test_ids && touched.fk_test_ids ? (
                      <p className="text-red-600 text-sm">
                        {errors.fk_test_ids}
                      </p>
                    ) : null}
                  </div>
                </div>
                {/* Location Multiselect */}
                <div className="basis-1/2">
                  <label
                    htmlFor="fk_location_ids"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Locations
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      // defaultValue={testList[0]}
                      isMulti
                      name="fk_location_ids"
                      id="fk_location_ids"
                      value={multiLocationId}
                      onChange={(e) => handleMultiLocation(e)}
                      onBlur={handleBlur}
                      options={locationList}
                      className="basic-multi-select"
                      classNamePrefix="Select Test"
                    />
                    {errors.fk_location_ids && touched.fk_location_ids ? (
                      <p className="text-red-600 text-sm">
                        {errors.fk_location_ids}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="flex gap-5 mt-3">
                {/* Discount */}
                <div className="mt-1 basis-1/2">
                  <div>
                    <label
                      htmlFor="cp_discountValue"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Discount Value
                    </label>
                  </div>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      value={values.cp_discountValue}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      placeholder="Enter Discount Value"
                      name="cp_discountValue"
                      autoComplete="off"
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
                    />
                    {errors.cp_discountValue && touched.cp_discountValue ? (
                      <p className="text-red-600 text-sm">
                        {errors.cp_discountValue}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-1 basis-1/2">
                  <div className="mb-2">
                    <label
                      htmlFor="cp_value_is_percentage"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Is Percentage
                    </label>
                  </div>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Switch
                      name="cp_value_is_percentage"
                      checked={isPercentage}
                      onChange={(e) => {
                        // console.log(e,)
                        setIsPercentage(e);
                      }}
                      className={categoryStatusToggle(
                        isPercentage ? "bg-cyan-600" : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={categoryStatusToggle(
                          isPercentage ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
              <div className="flex gap-5 mt-3">
                {/* Discount */}
                <div className="mt-1 basis-1/2">
                  <div>
                    <label
                      htmlFor="cp_code_prefix"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Coupon Code Prefix
                    </label>
                  </div>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      value={values.cp_code_prefix}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      placeholder="Enter Code Prefix"
                      name="cp_code_prefix"
                      autoComplete="off"
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
                    />
                    {errors.cp_code_prefix && touched.cp_code_prefix ? (
                      <p className="text-red-600 text-sm">
                        {errors.cp_code_prefix}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-1 basis-1/2">
                  <div className="mb-2">
                    <label
                      htmlFor="cp_is_random"
                      className="block text-sm font-medium text-gray-900"
                    >
                      {randomise ? "Randomized Codes" : "Serialized Codes"}
                    </label>
                  </div>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Switch
                      name="cp_is_random"
                      checked={randomise}
                      onChange={(e) => {
                        // console.log(e,)
                        setRandomise(e);
                      }}
                      className={randomiseStatusToggle(
                        randomise ? "bg-cyan-600" : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={randomiseStatusToggle(
                          randomise ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
              <div className="flex gap-5 mt-3">
                {/* Discount */}
                <div className="mt-1 basis-1/2">
                  <div>
                    <label
                      htmlFor="cp_code_count"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Coupon Code Count
                    </label>
                  </div>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      value={values.cp_code_count}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      placeholder="Enter Code Count"
                      name="cp_code_count"
                      autoComplete="off"
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
                    />
                    {errors.cp_code_count && touched.cp_code_count ? (
                      <p className="text-red-600 text-sm">
                        {errors.cp_code_count}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-shrink-0 justify-end px-4 py-4">
            <button
              type="submit"
              className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
            >
              {couponId ? "Update" : "Save"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddCoupon;
