import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Navigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleLogin, handleLogout } from "../../../redux/auth/actions";
import { toast, ToastContainer } from "react-toast";
//import { useAuth } from '../../../contexts/Auth'
//import { supabase } from '../../../supabase';
import { BASEURL } from "../../../helper/constant";
import AuthServices from "../../../ApiServices/AuthServices";
import Api from '../../../ApiServices/MasterServices';
import { setupInactivityTimer } from "../../../helper/commonHelper";
import loginBackgroundImage from "../../../../src/assets/images/login-background-image.jpg";
import { FaSpinner } from "react-icons/fa";

const Login = () => {
	const location = useLocation();
	const dispatch = useDispatch()
	const navigate = useNavigate()
	//const user = useSelector(state => state.auth.user)
	const user = localStorage.getItem("user");
	const [selectedValue, setSelectedValue] = useState("email");
	const [loginLoader, setLoginLoader] = useState(false);

	const handleRadioChange = (event) => {
		setSelectedValue(event.target.value);
	};


	useEffect(() => {
		if (user) {
			navigate("/");
		}
	}, [user])

	const getPermissionsWithRole = async (role_id) => {
		try {
			const res = await Api.getPermissionsByRoleID(role_id)
			if (res.status) {
				let prevIds = []
				res.data.data.permissions.map((p) => {
					prevIds.push(p.permission_id)
				})
				localStorage.setItem("user_permissions", JSON.stringify(res.data.data.permissions))
			}
		} catch (e) {
			console.log(e, "error in getPermissionsWithRole")
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		const body = {
			login_type: selectedValue,
			username_or_email: e.target.email.value,
			password: e.target.password.value
		}
		console.log(body);
		setLoginLoader(true);
		//dispatch(handleLogin(body))
		AuthServices.Login(body)
			.then((response) => {
				const { data, status } = response;
				if (status == 200 && data.status) {
					toast.success(data.message);
					localStorage.setItem("user", JSON.stringify(data.data.user));
					localStorage.setItem("favourites", JSON.stringify(data.data.favourites));
					localStorage.setItem("module", JSON.stringify(data.data.module));
					localStorage.setItem("user_loc_menus", data?.data?.user_loc_menus ? JSON.stringify(data.data.user_loc_menus) : JSON.stringify([{ label: "ALL", value: "all", }]));
					localStorage.setItem("userLocData", JSON.stringify(data.data.userLocData));
					localStorage.setItem(
						"userLocData",
						data.data.userLocData[0]?.value ? JSON.stringify(data.data.userLocData) : JSON.stringify([{ "value": "All", "label": "All", "loc_branch_code": "" }])
					);
					localStorage.setItem("token", data.data.token);
					localStorage.setItem("TBB_role_id", data.data.user.role_id);
					getPermissionsWithRole(data.data.user.role_id)
					setupInactivityTimer(dispatch);
					navigate("/");
				} else {
					setLoginLoader(false);
					toast.error(data.message);
				}
			})
			.catch((error) => {
				// const msg = "Failed catch Error";
				const msg = "Username or Password does not match, please try again";
				toast.error(msg);
				setLoginLoader(false);
			});
		//const { data, status } = AuthServices.Login(body);


	};
	return (
		<>
			<ToastContainer delay={2000} position="top-right" />
			<div className="flex h-screen flex-col justify-center py-16 sm:px-6 lg:px-8" style={{ backgroundImage: `url(${loginBackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
				<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
						<div className="text-center flex justify-center">
							<div className=" py-1 px-6 rounded">
								<img
									className="mx-auto h-12 w-auto"
									src={`${BASEURL}/images/logo.png`}
									alt="Your Company"
								/>
							</div>
						</div>
						<div className="p-4">
							<h2 className="text-center text-2xl font-medium tracking-tight text-gray-800">
								{/* Sign in to your account */}
								{/* { location.pathname === "/signUp" ? "Sign-Up to your account" : "Sign-In to your account"} */}
								Welcome Back
							</h2>
							<p className="text-center text-sm text-gray-700">
								Please enter your details.
							</p>
						</div>

						<form className="space-y-6" onSubmit={(e) => handleSubmit(e)}>
							<div>
								{/* <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label> */}
								<div className="mt-1">
									{/* <p className="text-md text-gray-700">
								Login with:
								</p> */}
									<div className="mt-1 mb-4">
										<label>
											<input
												type="radio"
												value="email"
												className="mr-2"
												checked={selectedValue === 'email'}
												onChange={handleRadioChange}
											/>
											<span className="text-gray-">Email</span>
										</label>
										<label>
											<input
												type="radio"
												value="username"
												className="mr-2 ml-2"
												checked={selectedValue === 'username'}
												onChange={handleRadioChange}
											/>
											<span>Employee Id</span>
										</label>
									</div>
								</div>
								<div className="mt-1">
									<input
										id="email"
										name="email"
										type="text"
										autoComplete="text"
										placeholder="Enter Email OR Username"
										required
										className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
									/>
								</div>
							</div>

							<div>
								{/* <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label> */}
								<div className="mt-1">
									<input
										id="password"
										name="password"
										type="password"
										autoComplete="current-password"
										placeholder="Password"
										required
										className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
									/>
								</div>
							</div>
							{/* <div className="relative flex items-center justify-between">
								<div className="flex">
									<div className="flex h-5 items-center">
										<input
											id="remember-pass"
											aria-describedby="remember-pass"
											name="remember-pass"
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
										/>
									</div>
									<div className="ml-3 text-sm">
										<label
											htmlFor="remember-pass"
											className="font-medium text-gray-700"
										>
											Remember for 30 days
										</label>
									</div>
								</div>
								<div>
									<Link to="/forgot-password" className="text-cyan-600 text-sm">Forgot Password ?</Link>
								</div>
							</div> */}

							<div className="text-center">
								<button
									disabled={loginLoader}
									type="submit"
									className="flex w-full justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
								>
									{location.pathname === "/signUp" ? "Sign-Up" : "Sign-In"} {loginLoader && <FaSpinner icon="spinner" className="spinner ml-2" />}
								</button>

								{/* <br/>
                { location.pathname === "/signUp" ? <Link className="py-4" to="/login">Sign In, here</Link> : <Link className="py-4" to="/signUp">Sign-Up, here</Link>} */}
							</div>

							{/* <div className="w-full justify-center text-center p-2 rounded-md shadow-sm">
                <h5 className="p-2 border-y-2" >Test Credentail</h5>
                <h6 className="pt-2">Email: ajay.chauhan@enpointe.io</h6>
                <h6>Password: 123456</h6>
              </div> */}
						</form>
					</div>
				</div>
			</div>
		</>
	);
};
export default Login;